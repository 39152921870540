import { useMutation, useQueryClient } from 'react-query';

import { getCookie } from '@pumpkincare/shared';
import { transformUser, USER_SELF_QUERY } from '@pumpkincare/user';

import { QUOTE_ID_COOKIE_NAME } from './constants';
import { COST_QUERY } from './cost-query';
import { PRICING_QUERY } from './pricing-query';
import { QUOTE_QUERY } from './quote-query';
import {
  deleteQuotePet,
  deleteQuotePetPlan,
  finalizeQuote,
  patchQuote,
  patchQuoteVetAttribution,
  putQuotePetPlan,
  putQuotePetPolicy,
} from './quotes-service';

export function useMutateQuote(quoteId = getCookie(QUOTE_ID_COOKIE_NAME)) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pets, vet, zipCode }) => patchQuote({ quoteId, pets, vet, zipCode }),

    {
      onMutate: async () => {
        await queryClient.cancelQueries([QUOTE_QUERY, quoteId]);

        const prevData = queryClient.getQueryData([QUOTE_QUERY, quoteId]);

        return { prevData };
      },
    }
  );
}

export function useMutateQuoteVet(quoteId = getCookie(QUOTE_ID_COOKIE_NAME)) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }) => patchQuoteVetAttribution(quoteId, payload),

    {
      onMutate: async () => {
        await queryClient.cancelQueries([QUOTE_QUERY, quoteId]);

        const prevData = queryClient.getQueryData([QUOTE_QUERY, quoteId]);

        return { prevData };
      },
    }
  );
}

export function useMutatePetPlan(quoteId = getCookie(QUOTE_ID_COOKIE_NAME)) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pet, plan }) =>
      pet.hasPrevent
        ? putQuotePetPlan(quoteId, pet, plan)
        : deleteQuotePetPlan(quoteId, pet),

    {
      onSettled: () => queryClient.invalidateQueries([QUOTE_QUERY, quoteId]),

      onMutate: async variables => {
        await queryClient.cancelQueries([QUOTE_QUERY, quoteId]);

        const prevData = queryClient.getQueryData([QUOTE_QUERY, quoteId]);

        queryClient.setQueryData([QUOTE_QUERY, quoteId], {
          ...prevData,

          pets: prevData.pets.map(pet =>
            pet.id === variables.pet.id
              ? {
                  ...pet,

                  hasPrevent: variables.pet.hasPrevent,
                  plan: variables.plan,
                }
              : pet
          ),
        });

        return { prevData };
      },
    }
  );
}

export function useDeleteQuotePet(quoteId = getCookie(QUOTE_ID_COOKIE_NAME)) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ quotePetId }) => deleteQuotePet({ quoteId, quotePetId }),

    {
      onSettled: () => {
        queryClient.invalidateQueries([QUOTE_QUERY, quoteId]);
        queryClient.invalidateQueries([COST_QUERY, quoteId]);
        queryClient.invalidateQueries([PRICING_QUERY, quoteId]);
      },

      onMutate: async variables => {
        await queryClient.cancelQueries([QUOTE_QUERY, quoteId]);

        const prevData = queryClient.getQueryData([QUOTE_QUERY, quoteId]);
        const { quotePetId } = variables;

        queryClient.setQueryData([QUOTE_QUERY, quoteId], {
          ...prevData,

          pets: prevData.pets.filter(pet => pet.id !== quotePetId),
        });

        return { prevData };
      },
    }
  );
}

export function useMutatePetPolicy(quoteId = getCookie(QUOTE_ID_COOKIE_NAME)) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ pet }) => putQuotePetPolicy({ quoteId, pet }),

    {
      onSettled: () => queryClient.invalidateQueries([QUOTE_QUERY, quoteId]),

      onMutate: async variables => {
        await queryClient.cancelQueries([QUOTE_QUERY, quoteId]);

        const prevData = queryClient.getQueryData([QUOTE_QUERY, quoteId]);

        queryClient.setQueryData([QUOTE_QUERY, quoteId], {
          ...prevData,

          pets: prevData.pets.map(pet =>
            pet.id === variables.pet.id ? variables.pet : pet
          ),
        });

        return { prevData };
      },
    }
  );
}

export function useFinalize() {
  const queryClient = useQueryClient();

  return useMutation(finalizeQuote, {
    onSuccess: ({ user }) =>
      queryClient.setQueryData([USER_SELF_QUERY], transformUser(user)),
  });
}
