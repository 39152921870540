import classNames from 'classnames';
import PropTypes from 'prop-types';

import { formatCurrency } from '@pumpkincare/shared';
import { Body2, LegalBody } from '@pumpkincare/shared/ui';

import CoverageDates from '../coverage-dates';
import CoverageDocuments from '../coverage-documents';

import styles from './pet-wellness-overview.css';

function PetWellnessOverview({
  classes,
  provider,
  entitlements,
  price,
  documents,
  dateConfig,
}) {
  // function handleDocumentClick({ id }) {
  //   downloadPlanDoc(id).then(url => {
  //     if (url) {
  //       window.open(url);
  //     } else {
  //       captureException(`No url returned for pet_plan_id ${id}`);
  //     }
  //   });
  // }

  function handleDocumentClick() {}

  return (
    <div className={classNames(styles.root, classes.root)}>
      <div className={styles.overview}>
        <div className={styles.header}>
          <h5>{provider}</h5>

          {price.cost ? (
            <LegalBody>
              <b>{formatCurrency(price.cost)}</b> / {price.frequency}
            </LegalBody>
          ) : null}
        </div>

        <div className={styles.entitlements}>
          <div className={styles.entitlementList}>
            {entitlements.map(entitlement => (
              <Body2 key={entitlement.description}>
                {entitlement.description}

                <span>
                  <b>{formatCurrency(entitlement.utilized, { hideZero: true })}</b>{' '}
                  of {formatCurrency(entitlement.total, { hideZero: true })}
                </span>
              </Body2>
            ))}
          </div>
        </div>

        {documents.length ? (
          <CoverageDocuments
            documents={documents}
            title='Full Benefit Details'
            handleDocumentClick={handleDocumentClick}
          />
        ) : null}
      </div>

      <div className={styles.datesAside}>
        {dateConfig.dates.length ? (
          <CoverageDates
            dates={dateConfig.dates}
            subtitle={dateConfig.subtitle}
            canContact={dateConfig.canContact}
          />
        ) : null}
      </div>
    </div>
  );
}

PetWellnessOverview.defaultProps = {
  classes: {},
  price: {},
  documents: [],
  entitlements: [],
  dateConfig: { dates: [] },
  provider: 'Sprout',
};

PetWellnessOverview.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),

  price: PropTypes.shape({
    cost: PropTypes.number,
    frequency: PropTypes.oneOf(['month', 'year']),
  }),

  dateConfig: PropTypes.shape({
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        subtitle: PropTypes.string,
      })
    ).isRequired,
  }),

  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      subtitle: PropTypes.string,
      processingText: PropTypes.string,
    })
  ),

  provider: PropTypes.string,
  entitlements: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      total: PropTypes.number,
      utilized: PropTypes.number,
    })
  ),
};

export default PetWellnessOverview;
