import Store from 'store';

import {
  CHAT_BUTTON,
  EXPERIMENT,
  FOR_CATS,
  FOR_DOGS,
  PLAN_SELECTION,
  QUOTE_FLOW,
} from './model/categories';
import {
  EMAIL,
  NUMBER_OF_PETS,
  NUMBER_OF_QUOTE_PETS,
  PET_TYPE,
  PREVENT_SELECTED,
  USER_ID,
  USER_TYPE,
} from './model/dimensions';
import { INITIATE_EXPERIMENT } from './model/events';

const tracksQueue = {
  isReady: false,
  events: [],
};

const experimentsQueue = {
  isReady: false,
  data: { analytics: false, featureFlags: false },
  events: [],
};

export function getTrackedCustomProps() {
  return (
    Store.get('trackedCustomProps') || {
      [USER_TYPE]: 'Visitor',
      [PET_TYPE]: 'None',
      [NUMBER_OF_PETS]: 0,
      [USER_ID]: 'None',
      [EMAIL]: 'None',
    }
  );
}

export function setTrackedCustomProps(payload) {
  const {
    userId,
    userPets = [],
    email,
  } = {
    ...getTrackedCustomProps(),
    ...payload,
  };

  Store.set('trackedCustomProps', {
    [USER_TYPE]: userId ? 'Policyholder' : 'Visitor',
    [PET_TYPE]: userPets.length ? userPets[0].species : 'None',
    [NUMBER_OF_PETS]: userPets.length.toString(),
    [USER_ID]: userId || 'None',
    [EMAIL]: email || 'None',
  });
}

function getPreventativeSelected(pets = []) {
  return getCompletedQuotePets(pets).some(pet => pet.preventativeSelected)
    ? 'Yes'
    : 'No';
}

function getCompletedQuotePets(pets = []) {
  return pets.filter(pet => !!pet.deductible);
}

function getExperimentInfo() {
  return {
    experiments: [],
  };
}

export function analyticsTrack(payload, customProperties) {
  if (tracksQueue.isReady) {
    const { event, ...rest } = payload;
    const properties = {
      ...customProperties,
      ...rest,
    };

    window.analytics.track(event, properties);
  } else {
    tracksQueue.events.push({ payload, customProperties });
  }
}

export function experimentsTrack(callback) {
  if (experimentsQueue.isReady) {
    const payload = callback(experimentsQueue.data.featureFlags);

    if (payload) {
      analyticsTrack(payload);
    }
  } else {
    experimentsQueue.events.push(callback);
  }
}

export function runQueuedTracks() {
  tracksQueue.isReady = true;

  tracksQueue.events.forEach(({ payload, customProperties }) => {
    analyticsTrack(payload, customProperties);
  });

  tracksQueue.complete = tracksQueue.events;
  tracksQueue.events = [];
}

export function runQueuedExperiments(data) {
  experimentsQueue.data = { ...experimentsQueue.data, ...data };
  experimentsQueue.isReady = Object.keys(experimentsQueue.data).every(
    datum => !!datum
  );

  if (experimentsQueue.isReady && !experimentsQueue.complete) {
    experimentsQueue.complete = [];

    experimentsQueue.events.forEach(callback => {
      analyticsTrack(callback(experimentsQueue.data.featureFlags));
    });

    experimentsQueue.complete = experimentsQueue.events;
    experimentsQueue.events = [];
  }
}

export function getAnonymousId() {
  return window.analytics?.user().anonymousId();
}

export function analyticsIdentify(traits, userId) {
  return window.analytics?.identify(traits, userId);
}

// ...AboutPumpkinEvents,
export function GAAboutClickPhoneNumber() {
  analyticsTrack(
    {
      category: 'About Pumpkin',
      event: 'Click Phone Number',
    },
    getTrackedCustomProps()
  );
}

// ...AddAPetEvents,
export function GAAddAPetClickDeleteAddedPet(location) {
  analyticsTrack(
    {
      category: 'Add A Pet',
      event: 'Delete added pet',
      label: location,
    },
    getTrackedCustomProps()
  );
}

// ...ChatEvents,
export function GAChatClickOpenChat(customProperties) {
  analyticsTrack(
    {
      category: CHAT_BUTTON,
      event: 'Click Open Chat Button',
    },
    customProperties
  );
}

export function GAChatClickCloseChat(customProperties) {
  analyticsTrack(
    {
      category: CHAT_BUTTON,
      event: 'Click Close Chat Button',
    },
    customProperties
  );
}

// ...ExperimentEvents,
export function GAExperimentPageView(title) {
  analyticsTrack(
    {
      category: EXPERIMENT,
      event: `Viewed ${title} Experiment Page`,
      ...getExperimentInfo(),
    },
    getTrackedCustomProps()
  );
}

export function GAFeatureFlagInitiateExperiment(
  experimentName,
  experimentGroup,
  customProperties
) {
  analyticsTrack(
    {
      category: experimentName,
      event: INITIATE_EXPERIMENT,
      label: experimentGroup,
    },
    customProperties
  );
}

// ...ForCats,
export function GAForCatsClickCreateCatsPlan(block, customProperties) {
  analyticsTrack(
    {
      category: FOR_CATS,
      event: "Click Create My Cat's Plan",
      label: block,
    },
    customProperties
  );
}

export function GAForCatsClickRevolutionPlus() {
  analyticsTrack(
    {
      category: FOR_CATS,
      event: 'Click Revolution Plus',
    },
    getTrackedCustomProps()
  );
}

export function GAForCatsClickSeeFullPrescribingInfo() {
  analyticsTrack(
    {
      category: FOR_CATS,
      event: 'Click See Full Prescribing Info.',
    },
    getTrackedCustomProps()
  );
}

// ...ForDogs,
export function GAForDogsClickCreateDogsPlan(block, customProperties) {
  analyticsTrack(
    {
      category: FOR_DOGS,
      event: "Click Create My Dog's Plan",
      label: block,
    },
    customProperties
  );
}

export function GAForDogsClickSeeFullPrescribingInfo() {
  analyticsTrack(
    {
      category: FOR_DOGS,
      event: 'Click See Full Prescribing Info.',
    },
    getTrackedCustomProps()
  );
}

// ...PlanSelectionEvents,
export function GAPlanSelectionSubmitPurchasePlan(pets) {
  analyticsTrack(
    {
      category: PLAN_SELECTION,
      event: 'Click Purchase Plan',
      [PREVENT_SELECTED]: getPreventativeSelected(pets),
      [NUMBER_OF_QUOTE_PETS]: `${getCompletedQuotePets(pets).length}`,
      ...getExperimentInfo(),
    },
    getTrackedCustomProps()
  );
}

export function GAPlanSelectionClickAddAPet(location) {
  analyticsTrack(
    {
      category: PLAN_SELECTION,
      event: 'Click Create Plan',
      label: location,
      ...getExperimentInfo(),
    },
    getTrackedCustomProps()
  );
}

export function GAPlanSelectionClickPurchaseInsuranceOnly(numPets) {
  analyticsTrack(
    {
      category: PLAN_SELECTION,
      event: 'Click to purchase insurance only',
      label: `${numPets}`,
      ...getExperimentInfo(),
    },
    getTrackedCustomProps()
  );
}

export function GAPlanSelectionClickSeeSamplePolicy() {
  analyticsTrack(
    {
      category: PLAN_SELECTION,
      event: 'Click Sample Policy',
      ...getExperimentInfo(),
    },
    getTrackedCustomProps()
  );
}

export function GAPlanSelectionClickSeeOtherPetPlan() {
  analyticsTrack(
    {
      category: PLAN_SELECTION,
      event: "Click Other Pet's Plan",
      ...getExperimentInfo(),
    },
    getTrackedCustomProps()
  );
}

// ...PurchaseEvents,
export function GAPurchaseEvent(pets) {
  const policyCount = pets.length;
  const planCount = pets.reduce(
    (count, pet) => (pet.preventativeSelected ? ++count : count),
    0
  );

  analyticsTrack(
    {
      category: 'Purchase Event',
      event: 'Finalize Success',
      label: `Policies: ${policyCount}, Plans: ${planCount}`,
    },
    getTrackedCustomProps()
  );
}

// ...QuoteFlowEvents,
export function GAQuoteFlowSubmitPetBreed(petBreed) {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Submit Pet Breed',
      label: petBreed,
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowSubmitPetCount({ totalCount }) {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Submit Pets',
      label: 'Total Pet Count',
      value: totalCount,
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowSubmitNameAndEmail() {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Submit Name & Email',
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowSubmitPayment(response) {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Submit Payment',
      label: response ? 'Success' : 'Failed',
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowClickToEditPetInfo(location) {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Click to edit pet info',
      label: location,
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowClickHowWillWeUseThis() {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Click How will we use this?',
    },
    getTrackedCustomProps()
  );
}

export function GAQuoteFlowClickCreatePlan(label) {
  analyticsTrack(
    {
      category: QUOTE_FLOW,
      event: 'Click Create Plan',
      label,
    },
    getTrackedCustomProps()
  );
}
